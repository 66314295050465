<template>
    <div>
        <navheader></navheader>
        <div class="liveDetailBox">
            <div class="liveDetailTitle">
                <div class="liveTitle">
                    <div class="crumbs">
                        <i><img src="../assets/img/loginIcon/index-icon.png" alt=""></i>首页 ><span> 课程列表 ></span><span class="color"> 课程详情</span>
                    </div>
                    <div class="classBox">
                        <div class="videoBox">
                            <img :src="liveData.logoUrl" alt="" v-if="liveData.logoUrl != null||liveData.logoUrl != undefined">
                            <img src="../assets/img/default_c.png" alt="" v-else>
                        </div>
                        <div class="describeBox">
                            <div class="title">
                                <p>{{liveData.title}}</p>
                            </div>
                            <div class="teacher">
                                <p>直播日期：{{liveData.validEndDate}}</p>
                                <p>直播时间：{{liveData.startTime}} ~ {{liveData.endTime}}</p>
                            </div>
                            <div class="buyBox">
                                <div v-if="optStatus == 1" class="watchLive">
                                    <router-link :to="{name: 'liveplay',query:{liveId: liveData.id}}" class="addCart">
                                        观看直播
                                    </router-link>
                                </div>
                                <div v-else>
                                    <div class="addCartBox">
                                        <div class="codeBox">
                                            <div class="addCart" @click="noPower">
                                                暂无权限
                                            </div>
                                            <div class="codeText" v-show="codeDisplay" @click="toCode">
                                                <p>输入直播码</p>
                                            </div>
                                            <div class="inputBox" v-show="inputDisplay">
                                               <el-input
                                                placeholder=""
                                                clearable
                                                v-model="inputValue"
                                                >
                                                </el-input> 
                                                <div class="confirmButton" @click="toJump">确定</div>
                                            </div>
                                        </div>
                                        <!-- <div>
                                            <router-link :to="{name: 'liveplay',query:{liveId: liveData.id}}" class="addCart">
                                                进入直播
                                            </router-link>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contentBox">
                <div class="detailBox">
                    <div class="leftBox">
                        <div class="tabBox">
                            <el-tabs v-model="activeName">
                                <el-tab-pane label="课程简介" name="first" :key="'first'">
                                    <div class="detailBox">
                                        <p>
                                            {{liveData.description}}
                                        </p>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                    <div class="teacherBox">
                        <div class="title">
                            相关课程讲师
                        </div>
                        <div class="head">
                            <div class="icon"></div>
                            <div class="text">
                                <p class="teacherName">{{liveData.teacherName}}</p>
                                <p class="classCount">课程数量：1</p>
                                <p class="learnPeople">学习人次：1134</p>
                            </div>
                        </div>
                        <div class="brief">
                            <p>{{liveData.intro}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <navfooter></navfooter>
    </div>
</template>
<script>
import navheader from '../components/NavHeader'
import navfooter from '../components/NavFooter'
export default {
    data() {
        return {
            liveId: this.$route.query.liveId,
            liveData: {},
            inputValue: '',
            codeDisplay: true,
            inputDisplay: false,
            optStatus: '',
            activeName: "first",
        }
    },
    metaInfo: {
        title: '直播详情-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    components: {
        navheader,
        navfooter,
    },
    mounted() {
        this.getLiveData();
    },
    methods: {
        // 没有权限
        noPower() {
            this.$message.error("您没有权限，请联系老师获取直播码")
        },
        // 输入直播码
        toCode() {
            // console.log("去输码")
            this.codeDisplay = false;
            this.inputDisplay = true;
        },
        // 跳转
        toJump() {
            // console.log(this.inputValue,"输入的验证码")
            this.verification();
        },
        verification() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/live/code.html',
                data: {
                    liveId: this.liveId,
                    code: this.inputValue
                }
            }).then((res) => {
                this.optStatus = 1;
                // console.log(this.optStatus)
                let errorCode = res.data.code;
                if(errorCode == 1000) {
                     this.$message.error(res.data.msg)
                     this.inputDisplay = true;
                     this.optStatus = 0;
                } else {
                    this.optStatus = 1;
                }
            })
        },
        getLiveData() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/live/detail.html',
                data: {
                    liveId: this.liveId
                }
            }).then((res) => {
                this.liveData = res.data.result.live;
                this.optStatus = res.data.result.live.optStatus
            })
        }
    }
}
</script>

<style lang="scss">
    .liveDetailBox {
        width: 100%;
        min-width: 1200px;
        background: #EEEEEE;
        margin-top: 70px;
        .liveDetailTitle {
            width: 100%;
            min-width: 1200px;
            height: 420px;
            background: #444444;
            .liveTitle {
                margin: 0 auto;
                width: 1200px;

            }
            .crumbs {
                font-size: 14px;
                color: #FFFFFF;
                font-family: Microsoft YaHei;
                line-height: 50px;
                i {
                    display: inline-block;
                    margin-right: 5px;
                    width: 14px;
                    height: 14px;
                }
                .color {
                    color: #FF5A74;
                }
            }
            .classBox {
                    display: flex;
                    justify-content: space-between;
                    .videoBox {
                        width: 560px;
                        height: 310px;
                        // background: #E5E5E5;
                    }
                    .describeBox {
                        padding-left: 30px;
                        padding-top: 30px;
                        box-sizing: border-box;
                        width: 615px;
                        height: 310px;
                        background: rgba($color: #ffffff, $alpha: 0.1);
                        .title {
                            margin-bottom: 21px;
                            padding-bottom: 10px;
                            width: 555px;
                            border-bottom: 1px solid #666666;
                            p {
                                font-size: 20px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                        }
                        .people {
                            display: flex;
                            align-items: center;
                            margin-bottom: 11px;
                            i {
                                margin-right: 10px;
                                display: inline-block;
                                width: 21px;
                                height: 13px;
                                background: white;
                            }
                            p {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #BBBBBB;
                            }
                        }
                        .teacher {
                            margin-bottom: 15px;
                            p {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #BBBBBB;
                                line-height: 30px;
                            }
                        }
                        .buyBox {
                            display: flex;
                            align-items: center;
                            .watchLive {
                                a {
                                    display: inline-block;
                                    width: 110px;
                                    height: 40px;
                                    background: #FF5A74;
                                    border-radius: 3px;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #FFFFFF;
                                    text-align: center;
                                    line-height: 40px;
                                }
                            }
                            .addCartBox {
                                .codeBox {
                                    display: flex;
                                    align-items: center;
                                }
                                .codeText {
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #BBBBBB;
                                    cursor: pointer;
                                }
                                .addCart {
                                    display: inline-block;
                                    margin-right: 20px;
                                    width: 110px;
                                    height: 40px;
                                    line-height: 40px;
                                    background: #FF5A74;
                                    border-radius: 3px;
                                    color: #ffffff;
                                    text-align: center;
                                    font-size: 14px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    cursor: pointer;
                                }
                                .el-input {
                                    margin-right: 3px;
                                    width: 110px;
                                    height: 40px;
                                }
                                .inputBox {
                                    display: flex;
                                    align-items: center;
                                    .confirmButton {
                                        cursor: pointer;
                                        width: 81px;
                                        height: 34px;
                                        border: 1px solid #FF5A74;
                                        line-height: 34px;
                                        text-align: center;
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #FF5A74;
                                        border-radius: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
        }
        .contentBox {
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            box-sizing: border-box;
            min-width: 1200px;
            background: #EEEEEE;
            .detailBox {
                margin: 0 auto;
                width: 1200px;
                display: flex;
                justify-content: space-between;
                .leftBox {
                    width: 860px;
                    height: 771px;
                    background: #FFFFFF;
                    .tabBox {
                        width: 860px;
                        // height: 803px;
                        background: #FFFFFF;
                        padding-left: 30px;
                        padding-top: 20px;
                        box-sizing: border-box;
                        .detailBox {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                        }
                        .el-tabs__header {
                            width: 800px;
                            .el-tabs__header {
                                padding: none;
                                padding-right: 70px;
                            }
                            .el-tabs__item {
                                font-size: 16px;
                                &:hover {
                                    color: #FF5A74;
                                }
                            }
                            .el-tabs__item.is-active {
                                font-size: 16px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FF5A74;
                            }
                            .el-tabs__active-bar {
                                width: 64px;
                                background-color: #FF5A74;
                            }
                        }
                        .el-tabs__content {
                            overflow: visible;
                        }
                    }
                }
                .teacherBox {
                    width: 319px;
                    height: 771px;
                    padding-left: 12px;
                    padding-top: 30px;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    .title {
                        width: 279px;
                        margin-bottom: 20px;
                        padding-bottom: 9px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        border-bottom: 2px solid #EEEEEE;
                    }
                    .head {
                        display: flex;
                        width: 279px;
                        margin-bottom: 20px;
                        padding-bottom: 11px;
                        border-bottom: 2px solid #EEEEEE;
                        .icon {
                            margin-right: 20px;
                            width: 105px;
                            height: 105px;
                            background: #E5E5E5;
                            border-radius: 50%;
                        }
                        .text {
                            padding-top: 10px;
                            .teacherName {
                                margin-bottom: 10px;
                                font-size: 20px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                            }
                            .classCount {
                                margin-bottom: 6px;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                            .learnPeople {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                    .brief {
                        width: 279px;
                        p {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                }
            }
        }
    }
</style>